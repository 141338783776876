/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { map, size } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { QUANTITY_DEFAULT } from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { isEqualVal } from 'helpers/string.helper';
import { formatMoneyVN } from 'helpers/math.helper';
import { resourceUrl } from 'helpers/url.helper';
import { includesId } from 'helpers/array.helper';
import { useBoothAppContext } from 'context/BoothAppContext';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import Button from 'components/button/Button';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import {
  filterListLayoutByQuantity,
  getListQuantityOfPerSheet,
} from './layout-helper';
import './select-layout.css';

function SelectLayout() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const { listLayoutPhotos } = useBoothAppContext();

  const { quantityPicOfPerSheet = QUANTITY_DEFAULT, frameId } = useAppSelector(
    (state) => state.selfeApp,
  );
  const { isVerticalScreen, isTurnOnPayment } = useAppSelector((s) => s.booth);

  const { second } = usePageTimer({
    forwardFunc: () => handleSelectLayout(undefined)(),
    isBeforeTransaction: true,
  });

  // danh sách layout theo frameId và số ảnh trên 1 sheet
  const listLayoutByQuantity = filterListLayoutByQuantity(
    listLayoutPhotos?.data,
    frameId,
    quantityPicOfPerSheet,
  );

  // danh sách của số ảnh layout theo frameId => [1, 3, 4, 6, 8]
  const listQuantityOfPerSheet = useMemo(
    () => getListQuantityOfPerSheet(listLayoutPhotos?.data, frameId),
    [frameId, listLayoutPhotos?.data],
  );

  const handleSelectQuantity = (amount: number) => () => {
    audio?.playClickToSelect?.();
    dispatch(setSelfeAppStateAction({ quantityPicOfPerSheet: amount }));
  };

  const handleSelectLayout = (id: number | undefined) => async () => {
    await dispatch(
      setSelfeAppStateAction({
        layoutId: id || listLayoutByQuantity?.[0]?.id,
      }),
    );
    audio?.playClickToSelect?.();
    navigate(getNextPath() || '');
  };

  useEffect(() => {
    if (
      listQuantityOfPerSheet?.[0] &&
      !includesId(listQuantityOfPerSheet, QUANTITY_DEFAULT)
    ) {
      dispatch(
        setSelfeAppStateAction({
          quantityPicOfPerSheet: listQuantityOfPerSheet?.[0],
        }),
      );
    }
  }, [dispatch, listQuantityOfPerSheet]);

  return (
    <>
      <div className="select-layout">
        <TimerText second={second} />
        {!isVerticalScreen && (
          <div className="select-layout__title page-title-margin">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={t(`${I18nNamespace.COMMON}:selectLayout`)}
              className="page-title"
            >
              {t(`${I18nNamespace.COMMON}:selectLayout`)}
            </Typography>
          </div>
        )}
        <div className="select-layout__quantity-group">
          {map(listQuantityOfPerSheet, (quantity) => {
            const isActive = !!isEqualVal(
              quantityPicOfPerSheet || QUANTITY_DEFAULT,
              quantity,
            );
            return (
              <Button
                key={quantity}
                color={isActive ? 'primary' : 'transparent'}
                data-label={
                  isTurnOnPayment
                    ? `${formatMoneyVN(
                        listLayoutByQuantity?.[0]?.prices?.[0]?.price || 0,
                      )} đ`
                    : ''
                }
                onClick={handleSelectQuantity(quantity)}
                className={cx('select-layout__quantity-button', {
                  'select-layout__quantity-button--active': isActive,
                })}
                noBorder={!isActive}
              >
                {quantity} {t('common:picture')}
              </Button>
            );
          })}
        </div>
        <div className="select-layout__img-examples">
          {size(listLayoutByQuantity) ? (
            map(listLayoutByQuantity, (item) => (
              <img
                className="transition-img"
                key={item?.id}
                src={resourceUrl(item?.imageTemplate)}
                onClick={handleSelectLayout(item?.id)}
                alt="example-img"
              />
            ))
          ) : (
            <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
              {t('common:noData')}
            </Typography>
          )}
        </div>
      </div>
      {isVerticalScreen && (
        <TitleAndLogo text={t(`${I18nNamespace.COMMON}:selectLayout`)} />
      )}
    </>
  );
}

export default SelectLayout;

import React from 'react';
import AppLayout from 'containers/layout/AppLayout';
import { AppContext } from 'context/AppContext';
import { LoggingContext } from 'context/LoggingContext';
import { SignalRContext } from 'context/SignalRContext';
import { SoundContext } from 'context/SoundContext';
import { ThemeContext } from 'context/ThemeContext';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { store } from 'store';
import 'swiper/css';

const router = createBrowserRouter([
  {
    path: '*',
    element: <AppLayout />,
  },
]);

function App() {
  return (
    <ReduxProvider store={store}>
      <LoggingContext>
        <SoundContext>
          <AppContext>
            <ThemeContext>
              <SignalRContext>
                <RouterProvider router={router} />
              </SignalRContext>
            </ThemeContext>
          </AppContext>
        </SoundContext>
      </LoggingContext>
    </ReduxProvider>
  );
}

export default App;

import { I18nNamespace } from 'constants/i18n.const';
import { PrintingAspectRatioTypes } from 'models/printing-photo/printing-edit-photo.model';
import { useMemo } from 'react';
import { useTranslation } from './useTranslation';

export const useOptions = () => {
  const { T } = useTranslation(I18nNamespace.COMMON);

  const statusOptions = useMemo(
    () => [
      {
        value: 1,
        label: T('active'),
      },
      {
        value: 2,
        label: T('inactive'),
      },
    ],
    [T],
  );

  const aspectRatioOptions = useMemo(
    () => [
      {
        value: PrintingAspectRatioTypes.ThreeTwo,
        label: 'Tỷ lệ ảnh 3:2',
        aspect: 3 / 2,
      },
      {
        value: PrintingAspectRatioTypes.TwoThree,
        label: 'Tỷ lệ ảnh 2:3',
        aspect: 2 / 3,
      },
      {
        value: PrintingAspectRatioTypes.OneOne,
        label: 'Tỷ lệ ảnh 1:1',
        aspect: 1 / 1,
      },
    ],
    [],
  );

  const rotateOptions = useMemo(
    () => [
      { value: 0, label: 'Quay 0°' },
      { value: 90, label: 'Quay 90°' },
      { value: 180, label: 'Quay 180°' },
      { value: 270, label: 'Quay 270°' },
    ],
    [],
  );

  return {
    statusOptions,
    aspectRatioOptions,
    rotateOptions,
  };
};

import {
  PingUploadBoothHubRequestModel,
  PingUploadBoothHubResponseModel,
} from 'models/printing-photo/upload-photo.model';
import { PREFIX_API } from 'constants/api.const';
import { baseSliceApi } from '../base.slice-api';

export const pingBoothSocketPhotoApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    pingUploadBoothHubApiAction: build.query<
      PingUploadBoothHubResponseModel,
      PingUploadBoothHubRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.API}/print/socket`,
        method: 'GET',
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const { useLazyPingUploadBoothHubApiActionQuery } =
  pingBoothSocketPhotoApi;

export const {
  endpoints: { pingUploadBoothHubApiAction },
} = pingBoothSocketPhotoApi;

import { PRINTING_TIME_TO_PROCESS_FILE_DURATION } from 'constants/photo.const';
import { useCheckPayment } from 'containers/selfe-booth/payment/useCheckPayment';
import { getFileNameByPath } from 'helpers/image.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { compact, map } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProcessPhotoTakenApiActionMutation } from 'store/api/photo-taken.slice-api';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';

function PrintingProcessLoading() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const [processPhotoTaken] = useProcessPhotoTakenApiActionMutation();
  const {
    transactionId,
    selectedUploadedPhoto,
    appliedVoucherValue,
    depositAmount,
  } = useAppSelector((s) => s.selfeApp);
  const { costOfPayment } = useCheckPayment();

  const handlePostProcessFile = async () => {
    const layoutAmount = 0;
    // payload cho api xử lý ảnh video
    const processFilePayload = {
      transactionId,
      layoutId: 2,
      listImages: map(compact(selectedUploadedPhoto), (item) => ({
        layoutId: (item?.width || 0) > (item?.height || 0) ? 2 : 3,
        fileName: getFileNameByPath(item?.image) || '',
        x: item?.x,
        y: item?.y,
        width: item?.width,
        height: item?.height,
        rotate: item?.rotate || 0,
      })),
      layoutAmount,
      printAmount: costOfPayment - layoutAmount,
      discount: appliedVoucherValue,
      deposit: depositAmount - appliedVoucherValue,
    };
    // process ảnh
    await processPhotoTaken(processFilePayload as any);
    setTimeout(() => {
      dispatch(setSelfeAppStateAction({ appLoading: false }));
      navigate(getNextPath() || '');
    }, PRINTING_TIME_TO_PROCESS_FILE_DURATION);
  };

  useEffect(() => {
    handlePostProcessFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setSelfeAppStateAction({ appLoading: true }));
  }, [dispatch]);

  return null;
}

export default PrintingProcessLoading;

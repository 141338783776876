import React from 'react';
import cx from 'classnames';
import PageActions from 'components/page-actions/PageActions';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import Typography from 'components/typography/Typography';
import Slider from 'components/slider/Slider';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useCheckPayment } from 'containers/selfe-booth/payment/useCheckPayment';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/store-hooks';
import InputNumber from 'components/input/InputNumber';
import './printing-portrait-layout.css';
import { AssetImage } from 'assets/v2/images/AssetImage';

const slides = [
  { id: 1, name: 'name 1' },
  { id: 2, name: 'name 2' },
  { id: 3, name: 'name 3' },
  { id: 4, name: 'name 4' },
  { id: 5, name: 'name 5' },
  { id: 6, name: 'name 6' },
  { id: 7, name: 'name 7' },
];

function PrintingPortraitLayout() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const { isOnline } = useAppContext();
  const { second } = usePageTimer();
  const { isEnoughDeposit } = useCheckPayment();
  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  // click Back
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  // click Back
  const handleNext = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  const renderPaymentActions = () => (
    <PageActions
      NextButtonProps={{
        children: T('common:continue'),
        disabled: !isEnoughDeposit || !isOnline,
      }}
      handleBack={handleBack}
      handleContinue={handleNext}
      className={cx({
        'printing-portrait-layout__page-actions--vertical': isVerticalScreen,
      })}
      // hideNext
    />
  );

  return (
    <>
      <div className="printing-portrait-layout">
        <TimerText second={second} />
        {isVerticalScreen && renderPaymentActions()}
        {!isVerticalScreen && (
          <div className="page-title-margin">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:selectPortraitLayout`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:selectPortraitLayout`)}
            </Typography>
          </div>
        )}
        <div className="printing-portrait-layout__wrapper">
          <div className={cx('printing-portrait-layout__payment-info-wrapper')}>
            <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
              Số lượng in: 04
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
              Thành tiền: 50.000đ
            </Typography>
          </div>
          <Slider
            OuterProps={{
              className: 'printing-portrait-layout__slider-wrapper',
            }}
            slides={slides}
            slidesPerView={2}
            spaceBetween={30}
            RenderSlide={(item) => (
              <div className="printing-portrait-layout__layout-item">
                <InputNumber />
                <img src={AssetImage.LayoutExample} alt="portrait layout" />
                <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
                  4 ảnh 3x4cm - 3 ảnh 4x6cm
                </Typography>
              </div>
            )}
          />
        </div>
      </div>
      {!isVerticalScreen && renderPaymentActions()}
      {isVerticalScreen && (
        <TitleAndLogo
          text={T(`${I18nNamespace.COMMON}:selectPortraitLayout`)}
        />
      )}
    </>
  );
}

export default PrintingPortraitLayout;

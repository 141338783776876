import { BROADCAST_EVENTS } from 'constants/dom-element.const';
import { TIME_BANKING_DEBOUNCE_TIME } from 'constants/photo.const';
import { useSoundContext } from 'context/SoundContext';
import { emitSeqLog } from 'functions/seq-logging.func';
import { toNumber } from 'lodash';
import { SocketEventDataModel } from 'models/photo/socket.model';
import { useCallback, useEffect } from 'react';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { useDebouncedCallback } from 'use-debounce';
import './qr-code.css';

function QRCodeBankingListener() {
  const dispatch = useAppDispatch();
  const { audio } = useSoundContext();
  const { depositAmount } = useAppSelector((state) => state.selfeApp);

  const handleQRBanking: any = useDebouncedCallback(
    useCallback(
      async (e: CustomEvent<SocketEventDataModel>) => {
        if (e?.detail?.orderId) {
          const bankingAmount = +toNumber(e?.detail?.amount) || 0;
          emitSeqLog?.({
            messageTemplate: `[QRCode] QR Banking: ${bankingAmount}`,
          });
          audio?.playDeposit?.();
          dispatch(
            setSelfeAppStateAction({
              momoTransactionExpireDate: null,
              depositAmount: depositAmount + bankingAmount,
            }),
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dispatch, depositAmount],
    ),
    TIME_BANKING_DEBOUNCE_TIME,
    { trailing: false, leading: true },
  );

  // Add event broadcast qr momo
  useEffect(() => {
    document.addEventListener(BROADCAST_EVENTS.QR_BANKING, handleQRBanking);
    return () => {
      document.removeEventListener(
        BROADCAST_EVENTS.QR_BANKING,
        handleQRBanking,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default QRCodeBankingListener;

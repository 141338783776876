import React from 'react';
import { ReactComponent as CarouselDot } from './carousel_dot.svg';
import { ReactComponent as CarouselDotActive } from './carousel_dot_active.svg';
import { ReactComponent as PeopleError } from './people_error.svg';
import { ReactComponent as CameraReplace } from './camera_replace.svg';
import { ReactComponent as CameraTaking } from './camera_taking.svg';
import { ReactComponent as CircleDollar } from './circle_dollar.svg';
import { ReactComponent as Round } from './round.svg';
import { ReactComponent as Countdown } from './countdown.svg';
import { ReactComponent as BackSpace } from './back-space.svg';
import { ReactComponent as Shift } from './shift.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Rotate } from './rotate-icon.svg';
import { ReactComponent as Flip } from './flip-icon.svg';
import { ReactComponent as DeleteSticker } from './icon-delete-sicker.svg';
import { ReactComponent as Refresh } from './refresh.svg';

export const Icons = {
  CarouselDot,
  CarouselDotActive,
  PeopleError,
  CameraReplace,
  CameraTaking,
  CircleDollar,
  Round,
  Countdown,
  BackSpace,
  Shift,
  Warning,
  Rotate,
  Flip,
  DeleteSticker,
  Refresh,
};

export const AssetIcon: {
  [key: string]: React.FC<React.SVGProps<SVGSVGElement>>;
} = {
  PictureOutline: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      width={24}
      height={24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
      />
    </svg>
  ),
  TrashOutline: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      width={24}
      height={24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
      />
    </svg>
  ),
  ChevronDown: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      width={24}
      height={24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.5 8.25-7.5 7.5-7.5-7.5"
      />
    </svg>
  ),
};

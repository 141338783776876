import React, { ComponentClass, FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ENABLE_MODULE_MASTER, MODULE_ID_KEY } from 'constants/common.const';
import {
  PrintingPhotoRouteConst,
  SelfeRouteConst,
} from 'constants/route.const';
import ModuleMaster from 'containers/_module-master/ModuleMaster';
import { isEqualVal } from 'helpers/string.helper';
import { MODULE_ID } from 'constants/photo.const';

interface WithAuthenticateOption {
  isEnabled?: boolean;
  needAuthenticated?: boolean;
  unMatchingRedirect?: string;
}

// Todo: refactor code
export default function withModuleMaster(
  InnerComponent: ComponentClass<any> | FC,
  customOptions?: WithAuthenticateOption,
): FC {
  const { isEnabled = false } = customOptions || {};
  const WithAuthenticate: FC = (props: any) => {
    const { ...otherProps } = props;
    const selectedModuleId = localStorage.getItem(MODULE_ID_KEY);
    const { pathname } = useLocation();

    localStorage.setItem(ENABLE_MODULE_MASTER, isEnabled ? '1' : '');

    if (isEnabled) {
      if (!selectedModuleId) {
        return <ModuleMaster />;
      }

      if (
        isEqualVal(selectedModuleId, MODULE_ID.PRINTING_BOOTH) &&
        isEqualVal(pathname, SelfeRouteConst.START_PAGE)
      ) {
        return (
          <Navigate to={{ pathname: PrintingPhotoRouteConst.START_PAGE }} />
        );
      }
    }

    return <InnerComponent {...otherProps} />;
  };

  return WithAuthenticate;
}

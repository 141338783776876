import { BROADCAST_EVENTS } from 'constants/dom-element.const';
import { TIME_BANKING_DEBOUNCE_TIME } from 'constants/photo.const';
import { compact, concat } from 'lodash';
import { SocketEventDataModel } from 'models/photo/socket.model';
import { useCallback, useEffect } from 'react';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { useDebouncedCallback } from 'use-debounce';

function UploadedPrintingPhotoListener() {
  const dispatch = useAppDispatch();
  const { uploadedPhoto } = useAppSelector((state) => state.selfeApp);

  const HandleListenUploadedPrintingPhotoSocket: any = useDebouncedCallback(
    useCallback(
      async (e: CustomEvent<SocketEventDataModel>) => {
        dispatch(
          setSelfeAppStateAction({
            uploadedPhoto: compact(concat(uploadedPhoto, e?.detail?.listImage)),
          }),
        );
      },
      [dispatch, uploadedPhoto],
    ),
    TIME_BANKING_DEBOUNCE_TIME,
    { trailing: false, leading: true },
  );

  useEffect(() => {
    document.addEventListener(
      BROADCAST_EVENTS.UPLOADED_PRINTING_IMAGE,
      HandleListenUploadedPrintingPhotoSocket,
    );
    return () => {
      document.removeEventListener(
        BROADCAST_EVENTS.UPLOADED_PRINTING_IMAGE,
        HandleListenUploadedPrintingPhotoSocket,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default UploadedPrintingPhotoListener;

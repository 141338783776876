export const APP_CONTENT_ID = 'app-content';
export const BROADCAST_EVENTS = {
  BILL_ACCEPTOR: 'EVT_BILL_ACCEPTOR',
  TAKE_PHOTO: 'EVT_TAKE_PHOTO',
  QR_BANKING: 'EVT_BANKING',
  UPLOADED_PRINTING_IMAGE: 'UPLOADED_PRINTING_IMAGE',
};
export const ELEMENT_IDS = {
  PHOTO_SHEET: 'pb-photo-sheet',
};

import useConditionalTimeout from 'beautiful-react-hooks/useConditionalTimeout';
import cx from 'classnames';
import CachedImage from 'components/cached-image/CachedImage';
import PageActions from 'components/page-actions/PageActions';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { PRINT_SHOW_BTN_NEXT_DURATION } from 'constants/photo.const';
import { emitSeqLog } from 'functions/seq-logging.func';
import { resourceUrl } from 'helpers/url.helper';
import { usePageTimer } from 'hooks/usePageTimer';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePrintPhotoApiActionMutation } from 'store/api/print.slice-api';
import { useAppSelector } from 'store/store-hooks';
import './printing-wait-for-printing.css';

function PrintingWaitForPrinting() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();

  const { getStartNodePath } = useNewPhotoLifeCycleStep();
  const [
    printPhotoApiAction,
    { isLoading: printApiLoading, isUninitialized: printApiUninitialized },
  ] = usePrintPhotoApiActionMutation();
  const [isShowButtonBack, setIsShowButtonBack] = useState(false);

  usePageTimer({
    forwardFunc: () => handleRedirectToStart(),
    isNotShowConfirm: true,
  });

  const { transactionId } = useAppSelector((state) => state.selfeApp);
  const { boothInfo } = useAppSelector((state) => state.booth);

  // Redirect back lại bắt đầu
  const handleRedirectToStart = () => {
    emitSeqLog?.({ messageTemplate: `[Print] End transaction!` });
    navigate(getStartNodePath() || '');
  };

  useEffect(() => {
    transactionId && printPhotoApiAction({ transactionId } as any);
  }, [printPhotoApiAction, transactionId]);

  // Show nút back sau khi chup ảnh thành công
  useConditionalTimeout(
    () => setIsShowButtonBack(true),
    PRINT_SHOW_BTN_NEXT_DURATION,
    !printApiLoading && !printApiUninitialized,
  );

  return (
    <>
      <div className="print-title-container">
        <div
          className={cx(
            'print-title-wrapper text-center whitespace-pre-line print-title-wrapper',
            {
              'is-has-mascot': boothInfo?.PrintMascotImage,
            },
          )}
        >
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H1}
            className="print-title"
            data-text={t(`${I18nNamespace.COMMON}:pleaseWaitForPrint`)}
          >
            {t(`${I18nNamespace.COMMON}:pleaseWaitForPrint`)}
          </Typography>
        </div>
        {!!boothInfo?.PrintMascotImage && (
          <div className="print-image-wrapper">
            <CachedImage
              imageUrl={resourceUrl(boothInfo?.PrintMascotImage)}
              alt="macos"
            />
          </div>
        )}
      </div>
      <PageActions
        hidePrev
        hideNext={!isShowButtonBack}
        NextButtonProps={{
          children: t('common:finish'),
        }}
        handleContinue={handleRedirectToStart}
      />
    </>
  );
}

export default PrintingWaitForPrinting;

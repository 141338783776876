import { AssetIcon } from 'assets/icon/Icons';
import cx from 'classnames';
import Button from 'components/button/Button';
import InputNumber from 'components/input/InputNumber';
import PageActions from 'components/page-actions/PageActions';
import Select from 'components/select/Select';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useCheckPayment } from 'containers/selfe-booth/payment/useCheckPayment';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { isEqualVal } from 'helpers/string.helper';
import { imageUrl } from 'helpers/url.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useOptions } from 'hooks/useOptions';
import { usePageTimer } from 'hooks/usePageTimer';
import { useTranslation } from 'hooks/useTranslation';
import { find, map } from 'lodash';
import { PrintingAspectRatioTypes } from 'models/printing-photo/printing-edit-photo.model';
import React, { useMemo, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useNavigate } from 'react-router-dom';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import './printing-edit-photo.css';

function PrintingEditPhoto() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const { isOnline } = useAppContext();
  const { second } = usePageTimer();
  const { isEnoughDeposit } = useCheckPayment();
  const { aspectRatioOptions, rotateOptions } = useOptions();
  const { selectedUploadedPhoto } = useAppSelector((s) => s.selfeApp);

  const [isShowEditPanel, setIsShowEditPanel] = useState(true);
  const [aspectRatio, setAspectRatio] = useState(
    aspectRatioOptions?.[0]?.value,
  );
  const [rotation, setRotation] = useState(rotateOptions?.[0]?.value);
  const [cropPos, setCropPos] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [currentCropPhoto, setCurrentCropPhoto] = useState(
    selectedUploadedPhoto?.[0]?.image,
  );

  const handleOnCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    dispatch(
      setSelfeAppStateAction({
        selectedUploadedPhoto: map(selectedUploadedPhoto, (item) =>
          isEqualVal(item?.image, currentCropPhoto)
            ? { ...item, ...croppedAreaPixels }
            : { ...item },
        ),
      }),
    );
  };

  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  // click Back
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  // click Back
  const handleNext = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  const handleToggleShowEditPanel = () => setIsShowEditPanel((s) => !s);

  const handleResetSetting = () => {
    setRotation(rotateOptions?.[0]?.value);
    setAspectRatio(aspectRatioOptions?.[0]?.value);
    setCurrentCropPhoto(selectedUploadedPhoto?.[0]?.image);
  };

  const aspectVal = useMemo(
    () =>
      find(aspectRatioOptions, (o) => isEqualVal(aspectRatio, o?.value))
        ?.aspect,
    [aspectRatio, aspectRatioOptions],
  );

  const renderPaymentActions = () => (
    <PageActions
      NextButtonProps={{
        children: T('common:continue'),
        disabled: !isEnoughDeposit || !isOnline,
      }}
      handleBack={handleBack}
      handleContinue={handleNext}
      className={cx({
        'printing-edit-photo__page-actions--vertical': isVerticalScreen,
      })}
    />
  );

  return (
    <>
      <div className="printing-edit-photo">
        <TimerText second={second} />
        {isVerticalScreen && renderPaymentActions()}
        {!isVerticalScreen && (
          <div className="page-title-margin printing-edit-photo__page-title">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:editPhoto`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:editPhoto`)}
            </Typography>
          </div>
        )}
        <div className="printing-edit-photo__wrapper">
          <div className="printing-edit-photo__preview-edit-wrapper">
            <div className={cx('printing-edit-photo__preview-image')}>
              <div className="dashed-border-select-frame printing-edit-photo__preview-image-wrapper">
                <div className="printing-edit-photo__cropper-wrapper">
                  <Cropper
                    image={imageUrl(currentCropPhoto || '')}
                    crop={cropPos}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={aspectVal}
                    onCropChange={setCropPos}
                    onCropComplete={handleOnCropComplete}
                    onZoomChange={setZoom}
                    classes={{
                      cropAreaClassName: 'printing-edit-photo__cropper-area',
                    }}
                  />
                </div>
              </div>
            </div>
            {isShowEditPanel && (
              <div className="printing-edit-photo__edit-panel">
                <Typography className="printing-edit-photo__edit-panel-title">
                  {T(`${I18nNamespace.COMMON}:cropPhoto`)}
                </Typography>
                <div className="printing-edit-photo__edit-panel--row">
                  <Select
                    value={aspectRatio}
                    options={aspectRatioOptions}
                    prefixIcon={<AssetIcon.PictureOutline />}
                    onChange={(val) =>
                      setAspectRatio(val as PrintingAspectRatioTypes)
                    }
                  />
                </div>
                <div className="printing-edit-photo__edit-panel--row">
                  <div className="printing-edit-photo__edit-panel--col">
                    <Select
                      value={rotation}
                      options={rotateOptions}
                      prefixIcon={<AssetIcon.PictureOutline />}
                      onChange={(val) => setRotation(val as number)}
                    />
                  </div>
                </div>
                <div className="printing-edit-photo__edit-panel--row">
                  <Button
                    color="primary"
                    className="printing-edit-photo__btn-re-setup"
                    onClick={handleResetSetting}
                  >
                    <AssetIcon.PictureOutline />
                    <Typography
                      variant={TYPOGRAPHY_VARIANTS.XS}
                      className="printing-edit-photo__text"
                    >
                      {T(`${I18nNamespace.COMMON}:reinstall`)}
                    </Typography>
                  </Button>
                </div>
                <div className="printing-edit-photo__edit-panel--h-line" />
                <div className="printing-edit-photo__edit-panel--row">
                  <div className="printing-edit-photo__edit-panel--list-crop-photo-thumb">
                    {map(selectedUploadedPhoto, (item, ind) => (
                      <button
                        type="button"
                        className={cx(
                          'printing-edit-photo__edit-panel--btn-crop-photo-thumb',
                          { active: isEqualVal(item?.image, currentCropPhoto) },
                        )}
                        onClick={() => setCurrentCropPhoto(item?.image)}
                      >
                        <img src={imageUrl(item?.image)} alt="crop-thumbnail" />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="printing-edit-photo__edit-actions">
            <div className="printing-edit-photo__edit-action-select-amount">
              <Typography>
                {T(`${I18nNamespace.COMMON}:numberOfPrint`)}:
              </Typography>
              <InputNumber />
            </div>
            <div className="printing-edit-photo__edit-action-crop">
              <button
                type="button"
                className="printing-edit-photo__btn-show-crop"
                onClick={handleToggleShowEditPanel}
              >
                <span className="printing-edit-photo__icon-crop">
                  <AssetIcon.PictureOutline />
                </span>
                <Typography>
                  {T(`${I18nNamespace.COMMON}:cropAndFlip`)}
                </Typography>
              </button>
            </div>
          </div>
        </div>
      </div>
      {!isVerticalScreen && renderPaymentActions()}
      {isVerticalScreen && (
        <TitleAndLogo text={T(`${I18nNamespace.COMMON}:uploadedPhoto`)} />
      )}
    </>
  );
}

export default PrintingEditPhoto;

import { DListResponse } from 'models/common.model';

export enum PaymentMethodEnum {
  CASH = 1,
  MOMO = 2,
}

export type TransactionModel = any;
export type FirstPushTransactionRequestModel = {
  id: string;
  deposit: number;
  voucherCode?: string;
  status?: number;
  code?: string;
  recordAt?: string;
  createdTime?: string;
  uploadTime?: string;
  paymentMethod?: PaymentMethodEnum;
};

export type FirstPushTransactionResponseModel = DListResponse<boolean>;

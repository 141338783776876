/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import cx from 'classnames';
import React, { FC, ReactElement, useCallback, useState } from 'react';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { map, range, size } from 'lodash';
import { Icons } from 'assets/icon/Icons';
import { isEqualVal } from 'helpers/string.helper';
import './slider.css';

export type Props<T = any> = SwiperProps & {
  RenderSlide?: (item: T) => ReactElement | string;

  slides: Array<T>;
  hiddenDot?: boolean;

  OuterProps?: React.HTMLAttributes<HTMLDivElement>;
};

const Slider: FC<Props> = ({
  RenderSlide,

  slides,
  slidesPerView = 1,
  hiddenDot,

  OuterProps,
  ...rest
}) => {
  const [swiperIns, setSwiperIns] = useState<SwiperClass | null>(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const handleSlideTo = useCallback(
    (index: number) => {
      if (swiperIns) {
        swiperIns.slideToLoop(index);
      }
    },
    [swiperIns],
  );

  return (
    <div
      {...OuterProps}
      className={cx('pb-slider-wrapper', OuterProps?.className)}
    >
      <Swiper
        onSwiper={setSwiperIns}
        onRealIndexChange={(swiperData) =>
          setSwiperIndex(swiperData?.realIndex || 0)
        }
        spaceBetween={50}
        slidesPerGroup={1}
        scrollbar={{ draggable: true }}
        autoplay={{ delay: 5000 }}
        loop
        {...rest}
        slidesPerView={slidesPerView}
        className={cx('pb-slider', rest?.className)}
      >
        {map(slides, (item, ind: number) => (
          <SwiperSlide>{RenderSlide ? RenderSlide?.(item) : <></>}</SwiperSlide>
        ))}
      </Swiper>
      {!hiddenDot && (
        <ul className="carousel-indicators">
          {map(
            range(Math.ceil(size(slides) / (+slidesPerView || 1))),
            (_, index: number) => (
              <button
                type="button"
                onClick={() => handleSlideTo(index)}
                key={index}
                className={`${isEqualVal(swiperIndex, index) ? 'active' : ''}`}
              >
                {isEqualVal(swiperIndex, index) ? (
                  <Icons.CarouselDotActive />
                ) : (
                  <Icons.CarouselDot />
                )}
              </button>
            ),
          )}
        </ul>
      )}
    </div>
  );
};

export default Slider;

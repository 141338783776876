import React from 'react';
import cx from 'classnames';
import Background from 'components/background/Background';
import CustomRoutes from 'components/custom-routes/CustomRoutes';
import Loader from 'components/loader/Loader';
import PageActions from 'components/page-actions/PageActions';
import PopupConfirm from 'components/popup/PopupConfirm';
import { APP_CONTENT_ID } from 'constants/dom-element.const';
import { I18nNamespace } from 'constants/i18n.const';
import {
  PrintingPhotoRouteConst,
  SelfeRouteConst,
} from 'constants/route.const';
import { BoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import { resourceUrl } from 'helpers/url.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/store-hooks';
import OfflinePopup from 'containers/common/offline-popup/OfflinePopup';
import PrintingEditPhoto from 'containers/printing-photo-booth/printing-edit-photo/PrintingEditPhoto';
import PrintingPayment from 'containers/printing-photo-booth/printing-payment/PrintingPayment';
import PrintingPhotoUpload from 'containers/printing-photo-booth/printing-photo-upload/PrintingPhotoUpload';
import UploadedPrintingPhotoListener from 'containers/printing-photo-booth/printing-photo-upload/UploadedPrintingPhotoListener';
import PrintingPortraitLayout from 'containers/printing-photo-booth/printing-portrait-layout/PrintingPortraitLayout';
import PrintingSelectPhoto from 'containers/printing-photo-booth/printing-select-photo/PrintingSelectPhoto';
import PrintingWaitForPrinting from 'containers/printing-photo-booth/printing-wait-for-printing/PrintingWaitForPrinting';
import PrintingStartPage from 'containers/printing-photo-booth/start-page/PrintingStartPage';
import PrintingProcessLoading from 'containers/printing-photo-booth/printing-process-loading/PrintingProcessLoading';
import EditPhoto from 'containers/selfe-booth/edit-photo/EditPhoto';
import Guide from 'containers/selfe-booth/guide/Guide';
import GuideApi from 'containers/selfe-booth/guide/GuideApi';
import Payment from 'containers/selfe-booth/payment/Payment';
import PhotoGuide from 'containers/selfe-booth/photo-guide/PhotoGuide';
import PhotoGuide2 from 'containers/selfe-booth/photo-guide/PhotoGuide2';
import PickPhoto from 'containers/selfe-booth/pick-photo/PickPhoto';
import Print from 'containers/selfe-booth/print/Print';
import QRCode from 'containers/selfe-booth/qr-code/QRCode';
import QRCodeBankingListener from 'containers/selfe-booth/qr-code/QRCodeBankingListener';
import SelectFrame from 'containers/selfe-booth/select-frame/SelectFrame';
import SelectLayout from 'containers/selfe-booth/select-layout/SelectLayout';
import SelectPaymentMethod from 'containers/selfe-booth/select-payment-method/SelectPaymentMethod';
import ShootingMode from 'containers/selfe-booth/shooting-mode/ShootingMode';
import ShootingPosition from 'containers/selfe-booth/shooting-position/ShootingPosition';
import Shooting from 'containers/selfe-booth/shooting/Shooting';
import StartPage from 'containers/selfe-booth/start-page/StartPage';
import withModuleMaster from 'hoc/withModuleMaster';
import './app-layout.css';

const router = [
  {
    path: SelfeRouteConst.HOME,
    element: <StartPage />,
  },
  {
    path: SelfeRouteConst.FRAME,
    element: <SelectFrame />,
  },
  {
    path: SelfeRouteConst.LAYOUT,
    element: <SelectLayout />,
  },
  {
    path: SelfeRouteConst.PAYMENT_METHOD,
    element: <SelectPaymentMethod />,
  },
  {
    path: SelfeRouteConst.PAYMENT,
    element: <Payment />,
  },
  {
    path: SelfeRouteConst.QR_CODE,
    element: <QRCode />,
  },
  {
    path: SelfeRouteConst.SHOOTING_POSITION,
    element: <ShootingPosition />,
  },
  {
    path: SelfeRouteConst.SHOOTING_MODE,
    element: <ShootingMode />,
  },
  {
    path: SelfeRouteConst.SHOOTING,
    element: <Shooting />,
  },
  {
    path: SelfeRouteConst.PICK_PHOTO,
    element: <PickPhoto />,
  },
  {
    path: `${SelfeRouteConst.EDIT_PHOTO}/*`,
    element: <EditPhoto />,
  },
  {
    path: SelfeRouteConst.PRINT,
    element: <Print />,
  },

  // printing module
  {
    path: PrintingPhotoRouteConst.HOME,
    element: <PrintingStartPage />,
  },
  {
    path: PrintingPhotoRouteConst.PHOTO_UPLOAD,
    element: <PrintingPhotoUpload />,
  },
  {
    path: PrintingPhotoRouteConst.SELECT_UPLOADED_PHOTO,
    element: <PrintingSelectPhoto />,
  },
  {
    path: PrintingPhotoRouteConst.EDIT_PHOTO,
    element: <PrintingEditPhoto />,
  },
  {
    path: PrintingPhotoRouteConst.PAYMENT,
    element: <PrintingPayment />,
  },
  {
    path: PrintingPhotoRouteConst.PROCESS_LOADING,
    element: <PrintingProcessLoading />,
  },
  {
    path: PrintingPhotoRouteConst.WAIT_FOR_PRINTING,
    element: <PrintingWaitForPrinting />,
  },
  {
    path: PrintingPhotoRouteConst.SELECT_PORTRAIT_LAYOUT,
    element: <PrintingPortraitLayout />,
  },

  // guide
  {
    path: SelfeRouteConst.PHOTO_GUIDE,
    element: <PhotoGuide />,
  },
  {
    path: SelfeRouteConst.PHOTO_GUIDE_2,
    element: <PhotoGuide2 />,
  },
  {
    path: SelfeRouteConst.GUIDE,
    element: <Guide />,
  },
  {
    path: SelfeRouteConst.GUIDE_API,
    element: <GuideApi />,
  },
];

function AppLayout() {
  useTranslation([
    I18nNamespace.COMMON,
    I18nNamespace.PAGE,
    I18nNamespace.WARNING,
  ]);
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { getNextPath, getPrevPath, currentRoute } = useNewPhotoLifeCycleStep();
  const { appLoading, modalConfirmState } = useAppSelector(
    (state) => state.selfeApp,
  );
  const { boothInfo, isVerticalScreen, loadingInList, loadingInitial } =
    useAppSelector((state) => state.booth);
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };
  const handleContinue = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  return (
    <BoothAppContext>
      <Loader
        id={APP_CONTENT_ID}
        className={cx(
          'font-Nunito no-drag select-none overflow-hidden w-screen h-screen flex justify-center app-content',
          {
            'app-vertical-screen': isVerticalScreen,
          },
        )}
        loading={appLoading || loadingInList || loadingInitial}
        outerSpinProps={{ className: 'app-content-pb-loader-icon-wrapper' }}
        wrapperChildren={
          <>
            {isVerticalScreen && !!boothInfo?.MascotLoadingBeforePrintImage && (
              <img
                src={resourceUrl(boothInfo?.MascotLoadingBeforePrintImage)}
                className="mascot-loading-img"
                alt="mascot loading"
              />
            )}
          </>
        }
      >
        <UploadedPrintingPhotoListener />
        <QRCodeBankingListener />
        <Background />
        {isVerticalScreen && (
          <PageActions
            {...pick(currentRoute, [
              'hidePrevNext',
              'hidePrev',
              'hideNext',
              'PrevButtonProps',
              'NextButtonProps',
            ])}
            handleBack={handleBack}
            handleContinue={handleContinue}
            className="page-action-vertical"
          />
        )}
        <CustomRoutes routes={router} />
        {!isVerticalScreen && (
          <PageActions
            {...pick(currentRoute, [
              'hidePrevNext',
              'hidePrev',
              'hideNext',
              'PrevButtonProps',
              'NextButtonProps',
            ])}
            handleBack={handleBack}
            handleContinue={handleContinue}
          />
        )}
        <PopupConfirm {...modalConfirmState} />
        <OfflinePopup />
      </Loader>
    </BoothAppContext>
  );
}

export default withModuleMaster(AppLayout);

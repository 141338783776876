import React, { FC } from 'react';
import cx from 'classnames';
import Typography from 'components/typography/Typography';
import { addZeroPrefix } from 'helpers/math.helper';
import './input.css';

export type InputNumberProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: number;
  onChange?: (v: number) => void;
  isError?: boolean;
};

const InputNumber: FC<InputNumberProps> = ({
  value = 1,
  className,
  onChange,
  ...rest
}) => {
  const handleOnChange =
    (isIncrease: boolean = true) =>
    () =>
      onChange?.(isIncrease ? value + 1 : Math.max(value - 1, 1));

  return (
    <div {...rest} className={cx('pb-input-number', className)}>
      <button type="button" onClick={handleOnChange(false)}>
        <Typography>-</Typography>
      </button>
      <div className="printing-edit-photo__amount-label">
        <Typography>{addZeroPrefix(value)}</Typography>
      </div>
      <button type="button" onClick={handleOnChange(true)}>
        <Typography>+</Typography>
      </button>
    </div>
  );
};

export default InputNumber;

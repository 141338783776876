import { useMemo } from 'react';
import { find } from 'lodash';
import { isEqualVal } from 'helpers/string.helper';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useAppSelector } from 'store/store-hooks';
import { useModal } from 'hooks/useModal';
import { useSoundContext } from 'context/SoundContext';
import { useTranslation } from 'hooks/useTranslation';
import { I18nNamespace } from 'constants/i18n.const';
import { PHOTO_PAGE } from 'constants/photo.const';
import moment from 'moment';
import { diffFromDate } from 'helpers/time.helper';
import { useNavigate } from 'react-router-dom';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useFirstPushTransactionApiActionMutation } from 'store/api/transaction.slice.api';
import { useCreateMomoTransactionApiActionMutation } from 'store/api/payment.slice-api';
import { PaymentMethodEnum } from 'models/photo/transaction.model';

export function useCheckPayment() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { currentLayout } = useBoothAppContext();
  const { audio } = useSoundContext();
  const { openConfirmModal, closeConfirmModal, openErrorModal } = useModal();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const [firstPushTransactionApiAction] =
    useFirstPushTransactionApiActionMutation();
  const [createMomoTransactionApiAction] =
    useCreateMomoTransactionApiActionMutation();

  const {
    quantitySheet,
    transactionId,
    depositAmount,
    appliedVoucherCode,
    appliedVoucherValue,
    momoPaymentAmount,
    momoTransactionExpireDate,
    isChooseQRCodeToPay,
  } = useAppSelector((state) => state.selfeApp);

  const costOfPayment = useMemo(
    () =>
      find(currentLayout?.prices, (o) =>
        isEqualVal(o?.numberOfPicture, quantitySheet),
      )?.price ||
      currentLayout?.prices?.[0]?.price ||
      0,
    [currentLayout?.prices, quantitySheet],
  );

  const getErrorMessageCreateQRMoMo = (res: any) => {
    if (res?.error || res?.data?.data?.resultCode !== 0) {
      return res?.error?.data?.Message || res?.data?.data?.message;
    }
  };

  const handleFirstSaveTransaction = () => {
    // Lưu transaction
    firstPushTransactionApiAction({
      id: transactionId,
      voucherCode: appliedVoucherCode,
      deposit: depositAmount - appliedVoucherValue,
      paymentMethod: isChooseQRCodeToPay
        ? PaymentMethodEnum.MOMO
        : PaymentMethodEnum.CASH,
    });
  };

  const handleCreateMomoQRCode = async (amount = 0) =>
    // create transaction
    createMomoTransactionApiAction({
      OrderInfo: T(`${I18nNamespace.COMMON}:funTransaction`),
      OrderId: `${transactionId}_${moment().unix()}`,
      Amount: amount || costOfPayment - depositAmount,
    }).then((res: any) => {
      if (getErrorMessageCreateQRMoMo(res)) {
        openErrorModal(getErrorMessageCreateQRMoMo(res) || undefined);
      }
      return res;
    });

  // User click thanh toán bằng QRCode
  const handleCheckBeforeCreateQRPayment = async () => {
    if (depositAmount < costOfPayment) {
      let isCanNext = true;
      if (
        momoPaymentAmount !== Math.abs(costOfPayment - depositAmount) ||
        !diffFromDate(moment().toDate(), momoTransactionExpireDate)
      ) {
        // nếu link qrcode momo hết hạn hoặc momoTransactionExpireDate = null
        // tạo link thanh toán MoMo mới
        const qrRes: any = await handleCreateMomoQRCode();
        if (getErrorMessageCreateQRMoMo(qrRes)) isCanNext = false;
      }
      if (isCanNext) {
        audio?.playContinueClick?.();
        navigate(getNextPath() || '');
      }
    } else if (depositAmount > costOfPayment) {
      audio?.playRedundantDeposit?.();
      openConfirmModal({
        content: `${T('page:changeMoneyWarning')}`,
        onOk: () => {
          audio?.playContinueClick?.();
          handleFirstSaveTransaction();
          closeConfirmModal?.();
          navigate(getNextPath(null, [PHOTO_PAGE.QR_CODE]) || '');
        },
      });
    } else {
      audio?.playContinueClick?.();
      handleFirstSaveTransaction();
      navigate(getNextPath(null, [PHOTO_PAGE.QR_CODE]) || '');
    }
  };

  // User click khi đủ tiền
  const handleBeforeNextWarningAboutMoney = () => {
    if (depositAmount < costOfPayment) return;
    if (depositAmount > costOfPayment) {
      audio?.playRedundantDeposit?.();
      openConfirmModal({
        content: `${T('page:changeMoneyWarning')}`,
        onOk: () => {
          audio?.playContinueClick?.();
          handleFirstSaveTransaction();
          closeConfirmModal?.();
          navigate(getNextPath() || '');
        },
      });
    } else {
      audio?.playContinueClick?.();
      handleFirstSaveTransaction();
      navigate(getNextPath() || '');
    }
  };

  return {
    costOfPayment,
    isEnoughDeposit: depositAmount >= costOfPayment,
    handleBeforeNextWarningAboutMoney,
    handleCheckBeforeCreateQRPayment,
    handleCreateMomoQRCode,
  };
}

import useInterval from 'beautiful-react-hooks/useInterval';
import cx from 'classnames';
import PageActions from 'components/page-actions/PageActions';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import CONFIG from 'config';
import { I18nNamespace } from 'constants/i18n.const';
import { SECOND_VAL } from 'constants/photo.const';
import { QUERY_STRING } from 'constants/route.const';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useTranslation } from 'hooks/useTranslation';
import { size } from 'lodash';
import { QRCodeCanvas } from 'qrcode.react';
import qs from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyPingUploadBoothHubApiActionQuery } from 'store/api/printing-photo/upload-photo.slice-api';
import { useAppSelector } from 'store/store-hooks';
import './printing-photo-upload.css';

function PrintingPhotoUpload() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const [pingUploadBoothHubApiAction] =
    useLazyPingUploadBoothHubApiActionQuery();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const { second } = usePageTimer();
  const { transactionId, uploadedPhoto } = useAppSelector((s) => s.selfeApp);
  const { isVerticalScreen, boothInfo } = useAppSelector((s) => s.booth);

  // click Back
  const handleNext = async () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  const renderPaymentActions = () => (
    <PageActions
      NextButtonProps={{
        children: T('common:continue'),
        disabled: !size(uploadedPhoto),
      }}
      handleContinue={handleNext}
      className={cx({
        'printing-photo-upload__page-actions--vertical': isVerticalScreen,
      })}
      hidePrev
    />
  );

  const uploadPrintingUrl = useMemo(
    () =>
      `${CONFIG.REACT_APP_FE_CLOUD_URL}/upload-photo?${qs.stringify({
        [QUERY_STRING.FE_CLOUD.TRANSACTION_ID]: transactionId,
        [QUERY_STRING.FE_CLOUD.BOOTH_CODE]: boothInfo?.boothCode,
      })}`,
    [boothInfo?.boothCode, transactionId],
  );

  useInterval(() => {
    pingUploadBoothHubApiAction();
  }, SECOND_VAL * 60);

  useEffect(() => {
    pingUploadBoothHubApiAction();
  }, [pingUploadBoothHubApiAction]);

  return (
    <>
      <div className="printing-photo-upload">
        <TimerText second={second} />
        {isVerticalScreen && renderPaymentActions()}
        {!isVerticalScreen && (
          <div className="page-title-margin mb-[6rem]">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:scanQRToUploadPhoto`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:scanQRToUploadPhoto`)}
            </Typography>
          </div>
        )}
        <div className="flex items-center justify-center printing-photo-upload__wrapper">
          <div className="dashed-border">
            <div className="printing-photo-upload__qr-wrapper">
              <QRCodeCanvas
                style={{ height: 'auto', maxWidth: '100%', width: '48rem' }}
                value={uploadPrintingUrl}
              />
              <div className={cx('printing-photo-upload__qr-link-wrapper')}>
                {/* <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
                  {uploadPrintingUrl}
                </Typography> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isVerticalScreen && renderPaymentActions()}
      {isVerticalScreen && (
        <TitleAndLogo text={T(`${I18nNamespace.COMMON}:scanQRToUploadPhoto`)} />
      )}
    </>
  );
}

export default PrintingPhotoUpload;
